import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useLayoutEffect, useEffect } from "react";
import { dispatchResizeEvent } from "@devowl-wp/headless-content-unblocker";
import { useBanner, useBannerActionLinks, useBannerHistory, usePlainCss, useRestrictScrollWhenVisible, bannerOverlayInlineStyle, bannerOverlayInlineStyleAlignment, BannerAnimatedContent, isHiddenDueLegal } from "../..";

var Banner = function Banner() {
  var banner = useBanner();
  var _banner$layout = banner.layout,
      overlay = _banner$layout.overlay,
      overlayBlur = _banner$layout.overlayBlur,
      _banner$customCss = banner.customCss,
      antiAdBlocker = _banner$customCss.antiAdBlocker,
      css = _banner$customCss.css,
      visible = banner.visible,
      animationVisible = banner.animationVisible,
      activeAction = banner.activeAction,
      skipOverlay = banner.skipOverlay,
      gotHiddenDueLegal = banner.gotHiddenDueLegal,
      pageRequestUuid4 = banner.pageRequestUuid4,
      individualPrivacyOpen = banner.individualPrivacyOpen;
  var hiddenDueLegal = isHiddenDueLegal(banner);
  var show = visible && !hiddenDueLegal || !!activeAction;
  usePlainCss(antiAdBlocker ? css : "");
  usePlainCss( // `-webkit-text-size-adjust:100%;`: https://stackoverflow.com/a/2711132/5506547
  "#".concat(pageRequestUuid4, ", #").concat(pageRequestUuid4, " * {box-sizing: border-box;backface-visibility:initial;text-transform:initial;-webkit-text-size-adjust:100%;}")); // Blur effect

  if (process.env.PLUGIN_CTX === "pro") {
    /* onlypro:start */
    // Compatibility with selectors which move the overlay container of the cookie banner under another container down to `<body`
    // and causing blurring to the cookie banner itself.
    var offCanvasWrapperSelectors = [// [Plugin Comp] https://powerpackelements.com/elementor-widgets/offcanvas-content/
    ".pp-offcanvas-container", // [Plugin Comp] https://wordpress.org/plugins/mobile-menu/
    ".mobmenu-push-wrap"];
    usePlainCss(show && animationVisible && overlay && +overlayBlur > 0 ? ["body", // [Plugin Comp] https://bookingkit.net/de/
    "#bkBgKeepPos"].concat(offCanvasWrapperSelectors).map(function (bodyId) {
      return "".concat(bodyId, " > :not(").concat(["#".concat(pageRequestUuid4), ".rcb-customize-banner-container", // [Plugin Comp] https://bookingkit.net/de/
      "#bkBgScrollPrevent", // [Plugin Comp] TranslatePress
      "#trp_ald_modal_container"].concat(offCanvasWrapperSelectors).join(","), ") { filter: blur(").concat(overlayBlur, "px); }");
    }).join("") : "");
    /* onlypro:end */
  }

  useRestrictScrollWhenVisible(show && overlay && animationVisible); // Listen to window resizes and resize the content automatically

  var _useState = useState(0),
      _useState2 = _slicedToArray(_useState, 2),
      setInvalidate = _useState2[1];

  var updateSize = function updateSize() {
    return setInvalidate(+new Date());
  };

  useLayoutEffect(function () {
    window.addEventListener("resize", updateSize);
    return function () {
      return window.removeEventListener("resize", updateSize);
    };
  }, []);
  useLayoutEffect(function () {
    dispatchResizeEvent(1000);
  }, [animationVisible, individualPrivacyOpen]); // When changes to the legal settings are done, make the banner visible or hide

  gotHiddenDueLegal && useEffect(function () {
    gotHiddenDueLegal(hiddenDueLegal);
  }, [hiddenDueLegal]);
  useBannerHistory();
  useBannerActionLinks();

  if (!show) {
    return null;
  }

  var content = h("div", bannerOverlayInlineStyleAlignment(banner), h(BannerAnimatedContent, null));
  return skipOverlay ? content : h("div", _extends({
    id: pageRequestUuid4
  }, bannerOverlayInlineStyle(banner)), content);
};

export { Banner };